/* TableStyles.css */

/* .table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
} */

/* .table th {
    background-color: #f2f2f2;
} */

.hovered {
    background-color: #1ecde4;
    /* Change the background color on hover */
}