.App {
  text-align: center;
  /* display: flex; */
  height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #c5ccda;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.codemirror_box {
  display: flex;
  flex-direction: column;
  /* max-height: 50%; */

  /* font-size: calc(8px + 2vmin); */
}

.wrap {
  display: flex;
  overflow: hidden;
}


.gutter {
  background: rgba(255, 255, 255, 0.05);
  /* flex-shrink: 0; */
}

.gutter-horizontal {
  cursor: ew-resize;
}

.gutter-vertical {
  cursor: ns-resize;
}


.wrap2 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

}


.Code_input {
  display: flex;
  flex-direction: column;
  /* height: 50vh; */
  /* overflow: auto; */
}

.output_table {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  overflow: auto;

}


.table th {
  background-color: #007bff;
  /* Blue color for header background */
  color: #fff;
  /* White color for header text */
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

/* Style the table rows */
.table tr {
  background-color: #f2f2f2;
  /* Light gray color for alternate rows */
}

/* Style even rows differently */
.table tr:nth-child(even) {
  background-color: #fff;
  /* White color for even rows */
}

/* Style cells */
.table td {
  padding: 10px;
}


.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eeeeee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}


.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  cursor: row-resize;
}